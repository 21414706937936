
    export default {
        head: function () {
            return {
                title: this.$store.state.topBannerData.meta_title || this.$nuxt.$options.head.title,
                meta: [
                    { hid: 'description', name: 'description', content: this.$store.state.topBannerData.meta_desc || this.$nuxt.$options.head.meta.find(meta => meta.name === 'description').content },

                    { hid: 'og:title', property: 'og:title', content: this.$store.state.topBannerData.meta_title || this.$nuxt.$options.head.title },
                    { hid: 'og:description', property: 'og:description', content: this.$store.state.topBannerData.meta_desc || this.$nuxt.$options.head.meta.find(meta => meta.name === 'description').content },
                    { hid: 'og:image', property: 'og:image', content: this.$store.state.topBannerData.background_image },

                    { hid: 'twitter:title', name: 'twitter:title', content: this.$store.state.topBannerData.meta_title || this.$nuxt.$options.head.title },
                    { hid: 'twitter:description', name: 'twitter:description', content: this.$store.state.topBannerData.meta_desc || this.$nuxt.$options.head.meta.find(meta => meta.name === 'description').content },
                    { hid: 'twitter:image', name: 'twitter:image', content: this.$store.state.topBannerData.background_image }
                ],
            };
        },
        components: {
            HeadingSection: () => import('../components/MainComponent/HeadingSection.vue'),
            TrustedBySection: () => import('../components/HomePage/TrustedBySection.vue'),
            ServicesSection: () => import('../components/HomePage/ServicesSection.vue'),
            OurWorkSection: () => import('../components/HomePage/OurWorkSection.vue'),
            TechnologySection: () => import('../components/HomePage/TechnologySection.vue'),
            ProjectsSection: () => import('../components/MainComponent/ProjectsSection.vue'),
        },
        computed: {
            topBannerData() {
                return this.$store.state.topBannerData;
            },
        },
        async asyncData(context) {
            await context.app.$getDataFromCache(`/wp-json/wp/v2/pages/7`)
                .then((response) => {
                    context.store.dispatch("setTopBannerData", response.data.acf);
                });
            await context.app.$getDataFromCache(`/wp-json/wp/v2/trusted_by`)
                .then((response) => {
                    context.store.dispatch("setTrustedByData", response.data);
                });
            await context.app.$getDataFromCache(`/wp-json/wp/v2/our_works?lang=lt`)
                .then((response) => {
                    context.store.dispatch("setOurWorkData", response.data);
                });
            await context.app.$getDataFromCache(`/wp-json/wp/v2/technologies?per_page=11`)
                .then((response) => {
                    context.store.dispatch("setTechnologyData", response.data);
                });
            await context.store.dispatch("setPageChangeAnimationValueFalse");
        },
    };
